import React from "react";
import styled from "styled-components";
import { AppLogo } from "../../../components/ui";
import { BasicLayout } from "../../../layouts/BasicLayout";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { SpecialOfferAccountForm } from "../../../components/PageSegments/special-offer/SpecialOfferAccountForm";

export default () => {
  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <LogoContainer>
          <AppLogo size="medium" />
        </LogoContainer>
        <SpecialOfferAccountForm />
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0;
  }
`;
